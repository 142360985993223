import { apiClient } from '.'
import { refetchOnlyParams } from './utils'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import type {
  CompanyPromocodesWithTotal,
  PromocodeValidated
} from './types/promocode'
import type { MaybeNull } from '../app/atoms/ts/generics'
import type {
  PromocodeBusiness,
  PromocodeCurrency,
  PromocodeRole,
  PromocodeRoute,
  PromocodeUsageOptions,
  PromocodeUsagePeriod
} from './types/enums'
import type { UseQueryOptions } from '@tanstack/react-query'
import type { WithPaginationFilters } from './types/pagination'

const path = '/promocodes'
export const PROMOCODES = 'PROMOCODES'

export type PromocodeGetParams = {
  nameEq?: string
  nameContains?: string
  startDatetimeGte?: string
  finishDatetimeLte?: string
  sort?: string
  isActiveEq?: boolean
  isSpentableEq?: boolean
  discountGte?: number
  discountLte?: number
  routeEq?: PromocodeRoute
  importEq?: string
  minValueGte?: number
  minValueLte?: number
  maxValueGte?: number
  maxValueLte?: number
  quantityOfUsesGte?: number
  quantityOfUsesLte?: number
  usagePerPeriodGte?: number
  usagePerPeriodLte?: number
  usagePeriod?: PromocodeUsagePeriod
  sessionPerPeriodGte?: number
  sessionPerPeriodLte?: number
  sessionPeriod?: PromocodeUsagePeriod

  usageOptionsEq?: PromocodeUsageOptions
  currencyEq?: PromocodeCurrency
  skip?: number
  limit?: number
}

type PromocodeDownloadGetParams = PromocodeGetParams & {
  projection?: string
}

export type CompanyPromocodesGetParams = WithPaginationFilters<{
  company: string
  promocodeName?: string
}>

export type PromocodeCreateBody = {
  discount?: number
  user?: MaybeNull<string>
  name: string
  amountPoints?: number
  startDatetime?: string
  finishDatetime?: string
  isActive: boolean
  isSpentable?: boolean
  business?: PromocodeBusiness
  route?: PromocodeRoute
  role: PromocodeRole
  minValue?: number
  maxValue?: number
  minPsychologistPrice?: number
  maxPsychologistPrice?: number
  quantityOfUses?: number
  usagePerPeriod?: number
  usagePeriod?: PromocodeUsagePeriod
  sessionPerPeriod?: number
  sessionPeriod?: PromocodeUsagePeriod
  usageOptions?: PromocodeUsageOptions
  companyId?: MaybeNull<string>
}

type PromocodePatchInput = {
  isActive?: boolean
  companyId?: string | null
  userId?: string | null
}

type PromocodeValidateGetParams = {
  promocodeName: string
  amountPoints: number
  client: string
}
const promocodes = {
  get: (params: PromocodeGetParams) =>
    apiClient
      .get(path, { params })
      .then<CompanyPromocodesWithTotal>(res => res.data),
  post: (body: PromocodeCreateBody) =>
    apiClient.post(path, body).then<string>(res => res.data.id),
  patch: (id: string, body: PromocodePatchInput) =>
    apiClient.patch(`${path}/${id}`, body),
  validate: (params: PromocodeValidateGetParams) =>
    apiClient
      .get<PromocodeValidated>(`${path}/validate`, { params })
      .then<PromocodeValidated>(res => res.data),
  download: (params: PromocodeDownloadGetParams) =>
    apiClient.get(`${path}/download`, { params }).then<string>(res => res.data),
  downloadAvitoFriendsStats: () =>
    apiClient.get(`${path}/avito-friends-stats/`).then<string>(res => res.data)
}

export const usePromocodeQuery = (
  params: PromocodeGetParams,
  options?: UseQueryOptions<CompanyPromocodesWithTotal>
) =>
  useQuery<CompanyPromocodesWithTotal>(
    [PROMOCODES, 'get', params],
    () => promocodes.get(params),
    options
  )

type PromocodeUpdateInput = PromocodePatchInput & { id: string }

export const usePromocodeUpdateQuery = () => {
  const queryClient = useQueryClient()
  return useMutation(
    [PROMOCODES, 'patch'],
    ({ id, ...input }: PromocodeUpdateInput) => promocodes.patch(id, input),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([PROMOCODES, 'get'])
      }
    }
  )
}

export const usePromocodeQueryRefetchOnly = (
  params: PromocodeGetParams
): ReturnType<typeof usePromocodeQuery> =>
  usePromocodeQuery(params, {
    ...refetchOnlyParams,
    initialData: {
      data: [],
      total: 0
    }
  })

export const usePromocodeCreateQuery = () => {
  const queryClient = useQueryClient()
  return useMutation(
    [PROMOCODES, 'create'],
    (body: PromocodeCreateBody) => promocodes.post(body),
    {
      onSuccess: () => {
        queryClient.invalidateQueries([PROMOCODES, 'get'])
      }
    }
  )
}

const usePromocodeValidateQuery = (
  params: PromocodeValidateGetParams,
  options?: UseQueryOptions<PromocodeValidated>
) =>
  useQuery<PromocodeValidated>(
    [PROMOCODES, 'validate', params],
    () => promocodes.validate(params),
    options
  )

export const usePromocodeValidateQueryRefetchOnly = (
  params: PromocodeValidateGetParams
): ReturnType<typeof usePromocodeValidateQuery> =>
  usePromocodeValidateQuery(params, {
    ...refetchOnlyParams,
    initialData: undefined,
    cacheTime: 0
  })

const usePromocodesDownloadQuery = (
  params: PromocodeDownloadGetParams,
  options?: UseQueryOptions<string>
) =>
  useQuery<string>(
    [PROMOCODES, 'download'],
    () => promocodes.download(params),
    options
  )

export const usePromocodesDownloadQueryRefetchOnly = (
  params: PromocodeDownloadGetParams
): ReturnType<typeof usePromocodesDownloadQuery> =>
  usePromocodesDownloadQuery(params, {
    ...refetchOnlyParams,
    initialData: ''
  })

const useAvitoFriendQuery = (options?: UseQueryOptions<string>) =>
  useQuery<string>(
    [PROMOCODES, 'download-avito-friends-stats'],
    () => promocodes.downloadAvitoFriendsStats(),
    options
  )

export const useAvitoFriendQueryRefetchOnly = (): ReturnType<
  typeof useAvitoFriendQuery
> =>
  useAvitoFriendQuery({
    ...refetchOnlyParams,
    initialData: ''
  })
