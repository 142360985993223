import FormItem from 'antd/es/form/FormItem'
import PhoneInput from 'antd-phone-input'
import React, { useCallback, useEffect, useMemo } from 'react'
import Title from 'antd/es/typography/Title'
import css from './index.module.scss'
import { Button, Form } from 'antd'
import type { PhoneNumber } from 'antd-phone-input'

import { phoneNumberValidator } from './validate'
import { useClientQuery, useUserUpdateQuery } from '../../../api/users'
import { useForm } from 'antd/es/form/Form'
import { useParams } from 'react-router-dom'
import { useRequestInterceptorsWithToken } from '../../../api'
import { useSuccessNotificationShow } from '../../store/notifications'

type FormValues = {
  phone: PhoneNumber | string
}

const useOnFinish = (id: string) => {
  const { mutateAsync, isLoading } = useUserUpdateQuery()
  const showSuccessNotification = useSuccessNotificationShow()
  return {
    isLoading,
    onFinish: useCallback(
      ({ phone }: FormValues) => {
        const parsedPhone =
          typeof phone === 'string'
            ? phone
            : `${phone.countryCode}${phone.areaCode}${phone.phoneNumber}`
        mutateAsync({
          id,
          phone: parsedPhone
        }).then(() => {
          showSuccessNotification('Информация о клиенте успешно обновлена')
        })
      },
      [id]
    )
  }
}

// eslint-disable-next-line max-lines-per-function
const Client = () => {
  const [form] = useForm<FormValues>()
  const { id } = useParams()
  const { isLoading, onFinish } = useOnFinish(id!)
  const hasLegacyApiToken = useRequestInterceptorsWithToken()

  const query = useClientQuery(id!, { enabled: hasLegacyApiToken })

  useEffect(() => {
    if (query.data) {
      form.resetFields()
      form.setFieldsValue(query.data)
    }
  }, [query.isLoading, id])

  const title = useMemo(
    () =>
      // eslint-disable-next-line max-len
      `Редактирование данных клиента ${query.data?.name} (${query.data?.leadId})`,
    [query.data]
  )

  return (
    <div className={css.flexCenter}>
      <div className={css.form}>
        <div className={css.container}>
          <Title level={3}>{title}</Title>
        </div>
        <Form
          disabled={query.isLoading || isLoading}
          form={form}
          initialValues={query.data}
          layout="vertical"
          onFinish={onFinish}
        >
          <FormItem
            required
            label="Номер телефона"
            name="phone"
            rules={[{ validator: phoneNumberValidator }]}
          >
            <PhoneInput distinct />
          </FormItem>
          <FormItem>
            <Button htmlType="submit" type="primary">
              Сохранить
            </Button>
          </FormItem>
        </Form>
      </div>
    </div>
  )
}

export default Client
