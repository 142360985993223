/**
 * Generated by orval v6.25.0 🍺
 * Do not edit manually.
 * YouTalk CRM API
 * OpenAPI spec version: 1.0.0
 */
import type {
  CompaniesGet200,
  CompaniesGetParams,
  CompanyCreate200,
  CompanyCreateBody,
  CompanyGet200,
  CompanyTopup200,
  CompanyTopupBody,
  CompanyUpdate200,
  CompanyUpdateBody
} from './index.schemas'
import { customInstance } from '../axios';



type SecondParameter<T extends (...args: any) => any> = Parameters<T>[1];


  /**
 * @summary Retrieve companies
 */
export const companiesGet = (
    params?: CompaniesGetParams,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CompaniesGet200>(
      {url: `/companies`, method: 'GET',
        params
    },
      options);
    }
  /**
 * @summary Create company
 */
export const companyCreate = (
    companyCreateBody: CompanyCreateBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CompanyCreate200>(
      {url: `/companies`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: companyCreateBody
    },
      options);
    }
  /**
 * @summary Update company
 */
export const companyUpdate = (
    id: string,
    companyUpdateBody: CompanyUpdateBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CompanyUpdate200>(
      {url: `/companies/${id}`, method: 'PATCH',
      headers: {'Content-Type': 'application/json', },
      data: companyUpdateBody
    },
      options);
    }
  /**
 * @summary Get company
 */
export const companyGet = (
    id: string,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CompanyGet200>(
      {url: `/companies/${id}`, method: 'GET'
    },
      options);
    }
  /**
 * @summary Topup for companies
 */
export const companyTopup = (
    companyTopupBody: CompanyTopupBody,
 options?: SecondParameter<typeof customInstance>,) => {
      return customInstance<CompanyTopup200>(
      {url: `/companies/topup`, method: 'POST',
      headers: {'Content-Type': 'application/json', },
      data: companyTopupBody
    },
      options);
    }
  export type CompaniesGetResult = NonNullable<Awaited<ReturnType<typeof companiesGet>>>
export type CompanyCreateResult = NonNullable<Awaited<ReturnType<typeof companyCreate>>>
export type CompanyUpdateResult = NonNullable<Awaited<ReturnType<typeof companyUpdate>>>
export type CompanyGetResult = NonNullable<Awaited<ReturnType<typeof companyGet>>>
export type CompanyTopupResult = NonNullable<Awaited<ReturnType<typeof companyTopup>>>
